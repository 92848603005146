import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const BROKER_CENTRE_KEY = RouteKeys.BROKER_CENTRE;

const brokerCentreMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(BROKER_CENTRE_KEY.BROKER_PORTAL),
    textKey: 'pageTitles.brokerPortal',
    enableActiveState: false,
  },
  {
    to: getPathFromRouteKey(BROKER_CENTRE_KEY.BROKER_PORTAL.LENDING_AREAS),
    textKey: 'pageTitles.lendingAreas',
  },
  {
    to: getPathFromRouteKey(BROKER_CENTRE_KEY.BROKER_PORTAL.APPROVED_APPRAISER_LIST),
    textKey: 'pageTitles.approvedAppraiserList',
  },
  {
    to: getPathFromRouteKey(BROKER_CENTRE_KEY.BROKER_PORTAL.SLIDING_SCALE_CALCULATOR),
    textKey: 'pageTitles.slidingScaleCalculator',
  },
  {
    to: getPathFromRouteKey(BROKER_CENTRE_KEY.MEET_THE_TEAM),
    textKey: 'pageTitles.meetTheTeam',
  },
];

export default brokerCentreMenu;
