import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import SanitizeHtml from 'sanitize-html';
import qs from 'qs';

import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import brokerCentreMenu from '../../../../data/page-menus/broker-centre';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import './style.scss';
import Input, { InputType } from '../../../../components/primitives/Input/Input';
import { useLocaleContext } from '../../../../context/Locale';
import { IconTypes } from '../../../../components/Icon/Icon';
import Button from '../../../../components/primitives/Button/Button';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import { formatPercent } from '../../../../shared/utils/formatNumbers';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';
import Link from '../../../../components/Link/Link';

type Props = PageProps & WithTranslation;

enum InputErrorType {
  EMPTY = 'EMPTY',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS',
}

const LendingAreas: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const initialState = {
    value: '',
    isInvalid: false,
    validationMessage: '',
  };

  const [lendingAreaInput, setLendingAreaInput] = useState(initialState);
  const [results, setResult] = useState<any>([]);

  const [formState, setFormState] = useState({
    submitted: false,
    submitting: false,
    shouldDisplayErrorMessage: false,
  });

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('lendingAreas.seo.title'),
    },
    page: {
      title: t('lendingAreas.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const validateInput = (value: string): { valid: boolean; errorType: InputErrorType | null } => {
    if (value.trim()) {
      if (SanitizeHtml(value).length !== value.length) {
        return { valid: false, errorType: InputErrorType.INVALID_CHARACTERS };
      }

      return { valid: true, errorType: null };
    }

    return { valid: false, errorType: InputErrorType.EMPTY };
  };

  const getErrorValidationMessage = (
    inputErrorType: InputErrorType,
    validationMessage: { emptyField: string; invalidCharacters: string }
  ): string => {
    if (inputErrorType === InputErrorType.EMPTY) {
      return validationMessage.emptyField;
    }

    return validationMessage.invalidCharacters;
  };

  const validateAndParseValue = () => {
    const validationResults = validateInput(lendingAreaInput.value);

    const validatedState = {
      ...lendingAreaInput,
      validationMessage: '',
      isInvalid: false,
    };

    validatedState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('lendingAreas.form.lendingAreaInput.validationMessage', { returnObjects: true })
      );
      validatedState.validationMessage = validationMessage;
    }

    return validatedState;
  };
  const handleFormChange = (value: string) => {
    setLendingAreaInput((prevState) => ({
      ...prevState,
      value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.persist();

    const lendingAreaInputTempState = validateAndParseValue();

    setLendingAreaInput((prevState) => ({
      ...prevState,
      ...lendingAreaInputTempState,
    }));

    if (lendingAreaInputTempState.isInvalid) {
      return false;
    }

    const finalObject = {
      searchString: lendingAreaInputTempState.value,
      locale: activeLocaleTag,
    };

    setFormState((prevState) => ({
      ...prevState,
      submitting: true,
      submitted: false,
    }));

    await fetch(`${process.env.GATSBY_LENDING_AREAS_API_URL}?${qs.stringify(finalObject)}`, {
      method: 'GET',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
        client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode || data.errorCode === 400) {
          setResult([]);
        } else if (Array.isArray(data.lendingAreas)) {
          setResult(data.lendingAreas);
        } else {
          setFormState((prevState) => ({
            ...prevState,
            submitting: false,
            submitted: true,
            shouldDisplayErrorMessage: true,
          }));
          return;
        }
        setFormState((prevState) => ({
          ...prevState,
          submitting: false,
          submitted: true,
          shouldDisplayErrorMessage: false,
        }));
      })
      .catch((err) => {
        console.error(`Failed: `, err);
        setFormState((prevState) => ({
          ...prevState,
          submitting: false,
          submitted: true,
          shouldDisplayErrorMessage: true,
        }));
      });

    return true;
  };

  const getResultRows = () => {
    if (results.length < 1) {
      return (
        <tr>
          <td colSpan={4}>
            <Trans i18nKey="lendingAreas.couldNotFind" t={t}>
              <Link to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM)} />
            </Trans>
          </td>
        </tr>
      );
    }

    return results.map((row: any) => {
      return (
        <tr key={Math.random()}>
          <td>{row.PostalCode__c}</td>
          <td>{row.Area_Name__c}</td>
          <td>{row.Territory__c}</td>
          <td>{formatPercent(+row.Max_LTV__c, activeLocaleTag)}</td>
        </tr>
      );
    });
  };

  return (
    <Layout options={layoutOptions} className="LendingAreas">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p>{t('lendingAreas.note')}</p>
            <form onSubmit={handleSubmit} className="LendingAreas__form">
              <Input
                id="userInput"
                isInvalid={lendingAreaInput.isInvalid}
                validationMessage={lendingAreaInput.validationMessage}
                options={{
                  type: InputType.TEXT,
                  label: t('lendingAreas.form.lendingAreaInput.label'),
                  inputProps: {
                    name: 'name',
                    value: lendingAreaInput.value,
                  },
                }}
                required
                onChange={handleFormChange}
              />

              {formState.submitted && formState.shouldDisplayErrorMessage && (
                <ErrorMessage>{t('lendingAreas.form.submissionValidation.error')}</ErrorMessage>
              )}
              <Button
                id="lending-areas-search"
                type="submit"
                iconOptions={{ icon: IconTypes.SEARCH }}
                styleOptions={{ isInline: true }}
                disabled={formState.submitting}
              >
                {formState.submitting
                  ? t('lendingAreas.form.submittingLabel')
                  : t('lendingAreas.form.submitLabel')}
              </Button>
              <p className="small" style={{ marginTop: 20 }}>
                {t('lendingAreas.footerNote')}
              </p>
            </form>
            {formState.submitted && (
              <div className="LendingAreas__results">
                <table>
                  <thead>
                    <tr>
                      <th>{t('lendingAreas.postalCode')}</th>
                      <th>{t('lendingAreas.postalDescription')}</th>
                      <th>{t('lendingAreas.territory')}</th>
                      <th>{t('lendingAreas.maximumLTV')}</th>
                    </tr>
                  </thead>
                  <tbody>{getResultRows()}</tbody>
                </table>
              </div>
            )}
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={brokerCentreMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(LendingAreas);
