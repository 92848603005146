import { LocaleTag, defaultLocaleTag } from '../../../config/locales';

/**
 *
 * @param amount number value that needs to be formatted
 * @param localeTag target locale, defaults to "defaultLocaleTag" value from config
 */
export const formatCurrency = (amount: number, localeTag: LocaleTag = defaultLocaleTag) => {
  return new Intl.NumberFormat(`${localeTag}-CA`, {
    style: 'currency',
    currency: 'CAD',
  })
    .format(amount)
    .replace('CA', '');
};

/**
 *
 * @param value Should be just regular % value, the formatter handles everything else.
 * @param localeTag
 */
export const formatPercent = (value: number, localeTag: LocaleTag = defaultLocaleTag) => {
  return new Intl.NumberFormat(`${localeTag}-CA`, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value / 100); // Percentages are fractions of 100
};
